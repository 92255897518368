<template>
  <b-container class="bg-white" fluid>
    <b-row class="mb-4">
      <b-col>
        <b-link :to="{ name: 'AppReporting' }">back to data loading</b-link>
        <b-button variant="outline-primary" class="mb-2 mx-4" @click="generateReport">download report</b-button>
        <b-button class="mb-2 mx-4" variant="outline-primary" v-b-toggle.sidebar-1>report options</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <div id="reportExport">
            <div style="font-family: Arial; font-size: 12px" v-if="showChangesSummary && options.changes.value">
              <h1 style="color: #00338D;">Changes summary</h1>
              <div v-if="controlsNew.length > 0">
                <h2 style="color: #00338D; margin-top: 50px;">New controls</h2>
                <div v-for="control in controlsNew" :key="control.Key">
                  <div>
                    <table style="font-family: Arial; font-size: 12px; background-color: #00338D; color: white;" width=100%>
                       <tr>
                         <td style="background-color: #00338D; color: white; font-size: 14px; font-weight: 700; padding: 10px 5px 10px 5px;">
                            <span v-if="options.key.value">{{control['Key']}} | </span>
                           {{control['Control Instance ID']}}
                          </td>
                        </tr>
                    </table>
                    <br />
                    <div><strong>Objective</strong><br /><markdown :content="control['Objective']"></markdown></div>
                    <div><strong>Description</strong><markdown :content="control['Control Description']"></markdown></div>
                    <div><strong>Frequency:</strong> {{control['Frequency']}}</div>
                    <div><strong>Business area: </strong> {{control['Business Area']}}</div>
                    <div><strong>Location:</strong> {{control['Location']}}</div>
                    <div><strong>Testing approach:</strong> {{control['Testing Approach']}}</div>
                    <br />
                    <div>
                      <strong>Operating effectiveness testing procedure</strong><br />
                      <markdown v-if="control['Procedure']" :content="control['Procedure']"></markdown>
                      <p v-if="!control['Procedure']">testing in progress</p>
                    </div>
                    <br /><br />
                  </div>
                </div>
              </div>
              <div v-else>
                <h2 style="color: #00338D; margin-top: 50px;">No new controls</h2>
              </div>
              <div v-if="controlsRetired.length > 0">
                <h2 style="color: #00338D; margin-top: 50px;">Retired controls</h2>
                <div v-for="control in controlsRetired" :key="control.Key">
                  <div>
                    <table style="font-family: Arial; font-size: 12px; background-color: #00338D; color: white;" width=100%>
                       <tr>
                         <td style="background-color: #00338D; color: white; font-size: 14px; font-weight: 700; padding: 10px 5px 10px 5px;">
                          <span v-if="options.key.value">{{control['Key']}} | </span>
                           {{control['Control Instance ID']}}
                          </td>
                        </tr>
                    </table>
                    <br />
                    <div><strong>Objective</strong><br /><markdown :content="control['Objective']"></markdown></div>
                    <div><strong>Description</strong><markdown :content="control['Control Description']"></markdown></div>
                    <div><strong>Frequency:</strong> {{control['Frequency']}}</div>
                    <div><strong>Business area: </strong> {{control['Business Area']}}</div>
                    <div><strong>Location:</strong> {{control['Location']}}</div>
                    <div><strong>Testing approach:</strong> {{control['Testing Approach']}}</div>
                    <br />
                    <div>
                      <strong>Operating effectiveness testing procedure</strong><br />
                      <markdown v-if="control['Procedure']" :content="control['Procedure']"></markdown>
                      <p v-if="!control['Procedure']">testing in progress</p>
                    </div>
                    <br /><br />
                  </div>
                </div>
              </div>
              <div v-else>
                <h2 style="color: #00338D; margin-top: 50px;">No newly retired controls</h2>
              </div>
              <div v-if="controlsEdited.length > 0">
                <h2 style="color: #00338D; margin-top: 50px;">Edited controls - markup</h2>
                <div v-for="control in controlsEdited" :key="control.Key">
                  <div>
                    <table style="font-family: Arial; font-size: 12px; background-color: #00338D; color: white;" width=100%>
                       <tr>
                         <td style="background-color: #00338D; color: white; font-size: 14px; font-weight: 700; padding: 10px 5px 10px 5px;">
                          <span v-if="options.key.value">{{control.new['Key']}} | </span>
                           <history-markup :current="control.new['Control Instance ID']" :version="control.old['Control Instance ID']" />
                          </td>
                        </tr>
                    </table>
                    <br />
                    <div><strong>Objective</strong><br /><history-markup :current="control.new['Objective']" :version="control.old['Objective']" /></div>
                    <div><strong>Description</strong><history-markup :current="control.new.ControlDescriptionRendered" :version="control.old.ControlDescriptionRendered" /></div>
                    <div><strong>Frequency:</strong> <history-markup :current="control.new['Frequency']" :version="control.old['Frequency']" /></div>
                    <div><strong>Business area: </strong> <history-markup :current="control.new['Business Area']" :version="control.old['Business Area']" /></div>
                    <div><strong>Location:</strong> <history-markup :current="control.new['Location']" :version="control.old['Location']" /></div>
                    <div><strong>Testing approach:</strong> <history-markup :current="control.new['Testing Approach']" :version="control.old['Testing Approach']" /></div>
                    <br />
                    <div>
                      <strong>Operating effectiveness testing procedure</strong><br />
                      <markdown v-if="control['Procedure']" :content="control['Procedure']"></markdown>
                      <p v-if="!control['Procedure']">testing in progress</p>
                    </div>
                    <br /><br />
                  </div>
                </div>
              </div>
              <div v-else>
                <h2 style="color: #00338D; margin-top: 50px;">No edited controls</h2>
              </div>
              <div v-if="findingsNew.length > 0">
                <h2 style="color: #00338D; margin-top: 50px;">New findings</h2>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Theme</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Finding</td>
                  </tr>
                  <tr v-for="finding in findingsNew" :key="finding.Key">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding['Component/s']}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><span v-if="options.key.value">{{finding['Key']}} | </span>{{finding['Control Instance ID']}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><strong>{{finding['Summary']}}</strong><div><markdown :content="finding['Description']"></markdown></div></td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <h2 style="color: #00338D; margin-top: 50px;">No new findings</h2>
              </div>
              <div v-if="findingsRetired.length > 0">
                <h2 style="color: #00338D; margin-top: 50px;">Retired findings</h2>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Theme</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Finding</td>
                  </tr>
                  <tr v-for="finding in findingsRetired" :key="finding.Key">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding['Component/s']}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><span v-if="options.key.value">{{finding['Key']}} | </span>{{finding['Control Instance ID']}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><strong>{{finding['Summary']}}</strong><div><markdown :content="finding['Description']"></markdown></div></td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <h2 style="color: #00338D; margin-top: 50px;">No newly retired findings</h2>
              </div>
              <div v-if="findingsEdited.length > 0">
                <h2 style="color: #00338D; margin-top: 50px;">Edited findings</h2>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Theme</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Finding</td>
                  </tr>
                  <tr v-for="finding in findingsEdited" :key="finding.new.Key">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding.new['Component/s']}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><span v-if="options.key.value">{{finding.new['Key']}} | </span>{{finding.new['Control Instance ID']}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><strong>{{finding.new['Summary']}}</strong><div><markdown :content="finding.new['Description']"></markdown></div></td>
                  </tr>
                </table>
                <h2 style="color: #00338D; margin-top: 50px;">Edited findings - markup</h2>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Theme</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Finding</td>
                  </tr>
                  <tr v-for="finding in findingsEdited" :key="finding.new.Key">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><history-markup :current="finding.new['Component/s']" :version="finding.old['Component/s']"/></td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><span v-if="options.key.value">{{finding.new['Key']}} | </span><history-markup :current="finding.new['Control Instance ID']" :version="finding.old['Control Instance ID']"/></td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><strong><history-markup :current="finding.new['Summary']" :version="finding.old['Summary']"/></strong><div><history-markup :current="finding.new.DescriptionRendered" :version="finding.old.DescriptionRendered"/></div></td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <h2 style="color: #00338D; margin-top: 50px;">No edited findings</h2>
              </div>
            </div>
            <div style="font-family: Arial; font-size: 12px" v-if="options.main.value">
              <h1 style="color: #00338D; margin-top: 50px;">Controls testing</h1>
              <h2 style="color: #00338D; margin-top: 50px;">Summary - version 1</h2>
              <table style="font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td> </td>
                  <td colspan="5">Control instances</td>
                </tr>
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td>Control theme</td>
                  <td>tested</td>
                  <td>without any findings</td>
                  <td>with design findings</td>
                  <td>tested for operating effectiveness</td>
                  <td>with OE findings</td>
                </tr>
                <tr v-for="component in controls" :key="component.component">
                  <td>{{component.component}}</td>
                  <td>{{component.statsControls}}</td>
                  <td>{{component.statsWithoutFindings}}</td>
                  <td>{{component.statsWithTodFindings}}</td>
                  <td>{{component.statsToeTested}}</td>
                  <td>{{component.statsToeFindings}}</td>
                </tr>
                <tr style="font-weight: 700;">
                  <td>TOTAL</td>
                  <td>{{stats.controls}}</td>
                  <td>{{stats.withoutFindings}}</td>
                  <td>{{stats.withTodFindings}}</td>
                  <td>{{stats.toeTested}}</td>
                  <td>{{stats.toeFindings}}</td>
                </tr>
              </table>
              <h2 style="color: #00338D; margin-top: 50px;">Summary - version 2</h2>
              <table style="font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td>Theme</td>
                  <td>Controls tested</td>
                  <td>Without design findings</td>
                  <td>With design findings</td>
                  <td>Tested for OE</td>
                  <td>Without OE findings</td>
                  <td>With OE findings</td>
                </tr>
                <tr v-for="component in controls" :key="component.component">
                  <td>{{component.component}}</td>
                  <td>{{component.statsControls}}</td>
                  <td>{{component.statsControls - component.statsWithTodFindings}}</td>
                  <td>{{component.statsWithTodFindings}}</td>
                  <td>{{component.statsToeTested}}</td>
                  <td>{{component.statsToeTested - component.statsToeFindings}}</td>
                  <td>{{component.statsToeFindings}}</td>
                </tr>
                <tr style="font-weight: 700;">
                  <td>TOTAL</td>
                  <td>{{stats.controls}}</td>
                  <td>{{stats.controls - stats.withTodFindings}}</td>
                  <td>{{stats.withTodFindings}}</td>
                  <td>{{stats.toeTested}}</td>
                  <td>{{stats.toeTested - stats.toeFindings}}</td>
                  <td>{{stats.toeFindings}}</td>
                </tr>
              </table>
              <div v-for="component in controls" :key="component.component">
                <h2 style="color: #00338D; margin-top: 50px;">{{component.component}}</h2>
                <div v-for="control in component.controls" :key="control.Key">
                  <div>
                    <table style="font-family: Arial; font-size: 12px; background-color: #00338D; color: white;" width=100%>
                       <tr>
                         <td style="background-color: #00338D; color: white; font-size: 14px; font-weight: 700; padding: 10px 5px 10px 5px;">
                          <span v-if="options.key.value">{{control['Key']}} | </span>
                           {{control['Control Instance ID']}}
                          </td>
                        </tr>
                    </table>
                    <br />
                    <div><strong>Objective</strong><br /><markdown :content="control['Objective']"></markdown></div>
                    <div><strong>Description</strong><markdown :content="control['Control Description']"></markdown></div>
                    <div><strong>Frequency:</strong> {{control['Frequency']}}</div>
                    <div><strong>Business area: </strong> {{control['Business Area']}}</div>
                    <div><strong>Location:</strong> {{control['Location']}}</div>
                    <div><strong>Testing approach:</strong> {{control['Testing Approach']}}</div>
                    <br />
                    <div>
                      <strong>Design assessment</strong>
                      <p v-if="control.tod.length === 0">No findings noted</p>
                      <div v-if="control.tod.length > 0">
                        <div v-for="issue in control.tod" :key="issue.Key">
                          <div>
                            <br /><strong><span v-if="options.key.value">{{issue['Key']}} | </span>{{issue['Summary']}}</strong>
                            <markdown :content="issue['Description']"></markdown>
                            <markdown :content="'**Management response:** ' + (issue['Additional Information'] + ' ').replace('Management response: ', '')"></markdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <strong>Operating effectiveness testing procedure</strong><br />
                      <markdown v-if="control['Procedure']" :content="control['Procedure']"></markdown>
                      <p v-if="!control['Procedure']">testing in progress</p>
                    </div>
                    <div v-if="control['Procedure'] && !control['Procedure'].startsWith('N/A')">
                      <strong>Operating effectiveness assessment</strong>
                      <p v-if="control.toe.length === 0">No findings noted</p>
                      <div v-if="control.toe.length > 0">
                        <div v-for="issue in control.toe" :key="issue.Key">
                          <div>
                            <br /><strong>{{issue['Summary']}}</strong>
                            <markdown :content="issue['Description']"></markdown>
                            <markdown :content="'**Management response:** ' + (issue['Additional Information'] + ' ').replace('Management response: ', '')"></markdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br /><br />
                  </div>
                </div>
              </div>
              <h1 style="color: #00338D;">Appendix - Findings noted</h1>
              <h2 style="color: #00338D; margin-top: 50px;">Open CAPs</h2>
              <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">MCRP 1/2</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">CAP no</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Name</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Completion date</td>
                </tr>
                <tr v-for="cap in caps" :key="'cap' + cap.number">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.MCRP}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.number}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.name}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.date}}</td>
                </tr>
              </table>
              <h2 style="color: #00338D; margin-top: 50px;">Controls findings</h2>
              <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Theme</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Finding</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Management response</td>
                </tr>
                <tr v-for="finding in findingsControls" :key="finding.key">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding['Component/s']}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding['Control Instance ID']}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><strong><span v-if="options.key.value">{{finding['Key']}} | </span>{{finding['Summary']}}</strong><div><markdown :content="finding['Description']"></markdown></div></td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><markdown :content="(finding['Additional Information'] + ' ').replace('Management response: ', '')"></markdown></td>
                </tr>
              </table>
              <div>
                <br /><br />
                <h1 style="color: #00338D;">Appendix - Scoping</h1>
                <h2 v-if="risks.length > 0" style="color: #00338D; margin-top: 50px;">Risk types</h2>
                <table v-if="risks.length > 0" style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Risk id</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Level 4 risk type</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Definition</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">RTC function</td>
                  </tr>
                  <tr v-for="(risk, i) in risks" :key="'risk' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.id}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.type}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.definition}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.function}}</td>
                  </tr>
                </table>
                <h2 v-if="controlsscope.length > 0" style="color: #00338D; margin-top: 50px;">Controls scoping</h2>
                <table v-if="controlsscope.length > 0" style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control id</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control name</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Risk types relevant to the Order</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Scope categorization</td>
                  </tr>
                  <tr v-for="(item, i) in controlsscope" :key="'controlscoping' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.id}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.name}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.risk}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.scope}}</td>
                  </tr>
                </table>
              </div>
              <div v-if="policies.length > 0">
                <br /><br />
                <h1 style="color: #00338D;">Appendix - Policies reviewed</h1>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Name</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Owner</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Reviewed in ACR 2022</td>
                  </tr>
                  <tr v-for="(item, i) in policies" :key="'policy' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.name}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.owner}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.reviewed}}</td>
                  </tr>
                </table>
              </div>
              <div v-if="procedures.length > 0">
                <br /><br />
                <h1 style="color: #00338D;">Appendix - Procedures reviewed</h1>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Name</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Owner</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Reviewed in ACR 2022</td>
                  </tr>
                  <tr v-for="(item, i) in procedures" :key="'policy' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.name}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.owner}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.reviewed}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </b-container>
      </b-col>
    </b-row>

    <b-sidebar id="sidebar-1" title="Report settings" shadow>
      <div class="px-3 py-1" v-for="(row, index) in options" :key="'options-' + index">
        <b-form-checkbox v-model="row.value" :value="true" :unchecked-value="false">{{row.text}}</b-form-checkbox>
      </div>
    </b-sidebar>

  </b-container>
</template>

<script>
import _ from 'lodash'
import MarkdownIt from 'markdown-it'
import Markdown from '@/components/Markdown.vue'
import HistoryMarkup from '@/components/HistoryMarkup.vue'
import config from './config.js'

export default {
  name: "ReportingAppMain",
  components: {
    HistoryMarkup,
    Markdown
  },
  data () {
    return {
      caps: [],
      controls: [],
      controlsEdited: [],
      controlsNew: [],
      controlsRetired: [],
      controlsscope: [],
      findingsControls: [],
      findingsEdited: [],
      findingsNew: [],
      findingsRetired: [],
      findingsWithdrawn: [],
      options: {
        changes: { value: true, text: 'changes section' },
        main: { value: true, text: 'main section' },
        key: { value: true, text: 'key' }
      },
      policies: [],
      procedures: [],
      risks: [],
      showChangesSummary: true,
      stats: null
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'Reporting app main', action: 'open Reporting app main page' })
    let dataOld = null
    const recordsRaw = {}
    config.files.forEach(x => {
      recordsRaw[x.id] = JSON.parse(localStorage.getItem('frmcloudReportingApp' + x.id))
    })
    if (recordsRaw.caps) {
      this.caps = recordsRaw.caps
    }
    if (recordsRaw.controlsscope) {
      this.controlsscope = recordsRaw.controlsscope
    }
    if (recordsRaw.risks) {
      this.risks = recordsRaw.risks
    }
    if (recordsRaw.policies) {
      this.policies = recordsRaw.policies
    }
    if (recordsRaw.procedures) {
      this.procedures = recordsRaw.procedures
    }
    if (recordsRaw.mainOld) {
      dataOld = recordsRaw.mainOld
    }
    const data = recordsRaw.main
    const componentsAll = []
    _.remove(data, x => {
      return x['Summary'].toUpperCase().match('NO DESIGN FINDING NOTED') || x['Summary'].toUpperCase().match('NO FINDINGS NOTED')
    })
    _.each(data, x => {
      if (x['Issue Type'] === 'Control') {
        componentsAll.push(x['Component/s'])
      }
      if (x['Finding Type'] === 'Design assessment (control)') {
        let summary = x['Summary']
        const space = summary.indexOf(' ')
        summary = summary.slice(space + 1)
        x['Summary'] = summary
      }
      if (x['Finding Type'] === 'Operating effectiveness assessment (control)') {
        let summary = x['Summary']
        const space = summary.indexOf(' ')
        summary = summary.slice(space + 1)
        x['Summary'] = summary
      }
    })

    // create findings table
    let findings = _.filter(data, x => {
      return x['Finding Type'] === 'Design assessment (control)'
    })
    findings = _.sortBy(findings, ['Component/s', 'Control Instance ID', 'Finding Type', 'Summary'])
    this.findingsControls = findings
    let findingsOe = _.filter(data, x => {
      return x['Finding Type'] === 'Operating effectiveness assessment (control)'
    })
    findingsOe = _.sortBy(findingsOe, ['Component/s', 'Control Instance ID', 'Finding Type', 'Summary'])
    this.findingsControls = findings.concat(findingsOe)
    this.findingsWithdrawn = _.remove(this.findingsControls, finding => {
      return finding['Validation'] === 'Withdrawn'
    })

    const components = _.uniq(componentsAll)
    const controls = []
    _.each(data, x => {
      if (x['Issue Type'] === 'Control') {
        x.tod = []
        x.toe = []
        x.toeTested = !x['Procedure'].startsWith('N/A')
        controls.push(x)
      }
    })
    _.each(controls, control => {
      const tod = _.filter(data, x => {
        return x['Finding Type'] === 'Design assessment (control)' && x['Control Instance ID'] === control['Control Instance ID'] && x['Validation'] !== 'Withdrawn'
      })
      control.tod = tod
      const toe = _.filter(data, x => {
        return x['Finding Type'] === 'Operating effectiveness assessment (control)' && x['Control Instance ID'] === control['Control Instance ID'] && x['Validation'] !== 'Withdrawn'
      })
      control.toe = toe
    })
    const controlsByComponent = []
    _.each(components, x => {
      const row = {
        component: x,
        controls: []
      }
      controlsByComponent.push(row)
    })
    _.each(controlsByComponent, x => {
      const rows = _.filter(controls, y => {
        return x.component === y['Component/s']
      })
      x.controls = _.orderBy(rows, ['Control Number'])
    })
    const controlsByComponentSorted = _.sortBy(controlsByComponent, ['component'])
    _.each(controlsByComponentSorted, x => {
      x.statsControls = x.controls.length
      x.statsWithoutFindings = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.tod.length === 0 && value.toe.length === 0 ? 1 : 0
        return accumulator + count
      }, 0)
      x.statsWithTodFindings = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.tod.length > 0 ? 1 : 0
        return accumulator + count
      }, 0)
      x.statsToeTested = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.toeTested ? 1 : 0
        return accumulator + count
      }, 0)
      x.statsToeFindings = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.toe.length > 0 ? 1 : 0
        return accumulator + count
      }, 0)
    })

    if (dataOld) {
      const componentsAllOld = []
      _.remove(dataOld, x => {
        return x['Summary'].toUpperCase().match('NO DESIGN FINDING NOTED') || x['Summary'].toUpperCase().match('NO FINDINGS NOTED')
      })
      _.each(dataOld, x => {
        if (x['Issue Type'] === 'Control') {
          componentsAllOld.push(x['Component/s'])
        }
        if (x['Finding Type'] === 'Design assessment (control)') {
          let summary = x['Summary']
          const space = summary.indexOf(' ')
          summary = summary.slice(space + 1)
          x['Summary'] = summary
        }
        if (x['Finding Type'] === 'Operating effectiveness assessment (control)') {
          let summary = x['Summary']
          const space = summary.indexOf(' ')
          summary = summary.slice(space + 1)
          x['Summary'] = summary
        }
      })
      const findingsOld = _.filter(dataOld, x => {
        return x['Finding Type'] === 'Design assessment (control)'
      })
      const findingsOeOld = _.filter(dataOld, x => {
        return x['Finding Type'] === 'Operating effectiveness assessment (control)'
      })
      const findingsControlsOld = findingsOld.concat(findingsOeOld)
      const findingsNew = []
      _.each(this.findingsControls, finding => {
        const existed = _.find(findingsControlsOld, ['Key', finding.Key])
        if (!existed) {
          findingsNew.push(finding)
        }
        this.findingsNew = findingsNew
      })
      const findingsRetired = []
      _.each(this.findingsWithdrawn, finding => {
        const old = _.find(findingsControlsOld, ['Key', finding.Key])
        if (!old) {
          findingsRetired.push(finding)
        } else {
          if (old['Validation'] !== 'Withdrawn') {
            findingsRetired.push(finding)
          }
        }
        this.findingsRetired = findingsRetired
      })
      const findingsEdited = []
      _.each(this.findingsControls, finding => {
        const findingOld = _.find(findingsControlsOld, ['Key', finding.Key])
        if (findingOld) {
          if (finding['Component/s'] !== findingOld['Component/s'] ||
          finding['Control Instance ID'] !== findingOld['Control Instance ID'] ||
          finding['Summary'] !== findingOld['Summary'] ||
          finding['Description'] !== findingOld['Description']) {
            const md = new MarkdownIt()
            md.set({ linkify: true })
            finding.DescriptionRendered = md.render(finding['Description'] + ' ')
            findingOld.DescriptionRendered = md.render(findingOld['Description'] + ' ')
            findingsEdited.push({
              new: finding,
              old: findingOld
            })
          }
        }
        this.findingsEdited = findingsEdited
      })
      const controlsOld = []
      _.each(dataOld, x => {
        if (x['Issue Type'] === 'Control') {
          x.toeTested = !x['Procedure'].startsWith('N/A')
          controlsOld.push(x)
        }
      })
      const controlsNew = []
      _.each(controls, control => {
        const existed = _.find(controlsOld, ['Key', control.Key])
        if (!existed) {
          controlsNew.push(control)
        }
        this.controlsNew = controlsNew
      })
      const controlsRetired = []
      _.each(controlsOld, control => {
        const exists = _.find(controls, ['Key', control.Key])
        if (!exists) {
          controlsRetired.push(control)
        }
        this.controlsRetired = controlsRetired
      })
      const controlsEdited = []
      _.each(controls, control => {
        const controlOld = _.find(controlsOld, ['Key', control.Key])
        if (controlOld) {
          if (control['Objective'] !== controlOld['Objective'] ||
          control['Control Description'] !== controlOld['Control Description'] ||
          control['Frequency'] !== controlOld['Frequency'] ||
          control['Business Area'] !== controlOld['Business Area'] ||
          control['Location'] !== controlOld['Location'] ||
          control['Testing Approach'] !== controlOld['Testing Approach'] ||
          control['Control Instance ID'] !== controlOld['Control Instance ID']
          ) {
            const md = new MarkdownIt()
            md.set({ linkify: true })
            control.ObjectiveRendered = md.render(control['Objective'])
            controlOld.ObjectiveRendered = md.render(controlOld['Objective'])
            control.ControlDescriptionRendered = md.render(control['Control Description'])
            controlOld.ControlDescriptionRendered = md.render(controlOld['Control Description'])
            controlsEdited.push({
              new: control,
              old: controlOld
            })
          }
        }
        this.controlsEdited = controlsEdited
      })
    } else {
      this.showChangesSummary = false
    }

    const stats = {
      controls: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsControls
      }, 0),
      withoutFindings: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsWithoutFindings
      }, 0),
      withTodFindings: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsWithTodFindings
      }, 0),
      toeTested: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsToeTested
      }, 0),
      toeFindings: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsToeFindings
      }, 0)
    }
    this.controls = controlsByComponentSorted
    this.stats = stats
  },
  methods: {
    generateReport: function () {
      const start = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Report</title></head><body>"
      const end = "</body></html>"
      const html = start + document.getElementById('reportExport').innerHTML + end
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = 'report.doc'
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
