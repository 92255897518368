<template>
<b-col>
  <b-container fluid class="bg-white">
    <b-row>
      <b-col class="my-5"><h3>By number of cases</h3></b-col>
    </b-row>
    <b-row cols="2">
      <b-col>
        <div class="mb-4" v-for="chart in barCharts1" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
      <b-col>
        <div class="mb-4" v-for="chart in barCharts2" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
    </b-row>
    <b-row cols="2">
      <b-col>
        <div class="my-5" v-for="chart in barChartsStacked3" :key="chart.key">
          <bar-stacked-series v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
      <b-col>
        <div class="my-5" v-for="chart in barChartsStacked4" :key="chart.key">
          <bar-stacked-series v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-5"><h3>By fines in USD @ historical FX</h3></b-col>
    </b-row>
    <b-row cols="2">
      <b-col>
        <div class="mb-4" v-for="chart in barCharts3" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
      <b-col>
        <div class="mb-4" v-for="chart in barCharts4" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
    </b-row>
    <b-row cols="2">
      <b-col>
        <div class="my-5" v-for="chart in barChartsStacked" :key="chart.key">
          <bar-stacked-series v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
      <b-col>
        <div class="my-5" v-for="chart in barChartsStacked2" :key="chart.key">
          <bar-stacked-series v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-col>
</template>

<script>
import _ from 'lodash'
// import * as echarts from 'echarts'
import dataGetters from '@/libs/dataGetters'
// import theme from '@/libs/colorTheme'
import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'
import BarStackedSeries from '@/components/charts/v1/barStackedSeries.vue'
// echarts.registerTheme('kpmg', theme)

function getHeight (data) {
  return (Math.floor(data.length / 10) + 1) * 140 + 'px'
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  name: 'CasesDataStrats',
  components: {
    BarStackedSeries,
    BarStackedSimple
  },
  computed: {
    barCharts1: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'object',
          property: 'coverage',
          nameProperty: 'name',
          nameSeries: 'Coverage',
          header: 'Coverage',
          headerRecord: 'types'
        },
        {
          dataGetter: 'array',
          property: 'jurisdictions',
          nameProperty: 'country',
          nameSeries: 'Countries',
          header: 'Countries',
          headerRecord: 'countries'
        },
        {
          dataGetter: 'array',
          property: 'controlrisks',
          nameProperty: 'name',
          nameSeries: 'Control risks',
          header: 'Control risks',
          headerRecord: 'control risks'
        },
        {
          dataGetter: 'array',
          property: 'workflow',
          nameProperty: 'name',
          nameSeries: 'Review status',
          header: 'Review status',
          headerRecord: 'review status'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barCharts2: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'array',
          property: 'assetclasses',
          nameProperty: 'name',
          nameSeries: 'Asset classes',
          header: 'Asset classes',
          headerRecord: 'asset classes'
        },
        {
          dataGetter: 'array',
          property: 'producttypes',
          nameProperty: 'name',
          nameSeries: 'Product types',
          header: 'Product types',
          headerRecord: 'product types'
        },
        {
          dataGetter: 'array',
          property: 'risks',
          nameProperty: 'name',
          nameSeries: 'MAR risks',
          header: 'MAR risks',
          headerRecord: 'MAR risks'
        },
        {
          dataGetter: 'property',
          property: 'year',
          nameProperty: 'year',
          nameSeries: 'Year',
          header: 'Year',
          headerRecord: 'years'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barCharts3: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'arraySum',
          property: 'jurisdictions',
          nameProperty: 'country',
          sumProperty: 'loss',
          nameSeries: 'Countries',
          header: 'Countries',
          headerRecord: 'countries'
        },
        {
          dataGetter: 'arraySum',
          property: 'controlrisks',
          nameProperty: 'name',
          sumProperty: 'loss',
          nameSeries: 'Control risks',
          header: 'Control risks',
          headerRecord: 'control risks'
        },
        {
          dataGetter: 'arraySum',
          property: 'workflow',
          nameProperty: 'name',
          sumProperty: 'loss',
          nameSeries: 'Review status',
          header: 'Review status',
          headerRecord: 'review status'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barCharts4: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'arraySum',
          property: 'assetclasses',
          nameProperty: 'name',
          sumProperty: 'loss',
          nameSeries: 'Asset classes',
          header: 'Asset classes',
          headerRecord: 'asset classes'
        },
        {
          dataGetter: 'arraySum',
          property: 'producttypes',
          nameProperty: 'name',
          sumProperty: 'loss',
          nameSeries: 'Product types',
          header: 'Product types',
          headerRecord: 'product types'
        },
        {
          dataGetter: 'arraySum',
          property: 'risks',
          nameProperty: 'name',
          sumProperty: 'loss',
          nameSeries: 'MAR risks',
          header: 'MAR risks',
          headerRecord: 'MAR risks'
        },
        {
          dataGetter: 'propertySum',
          property: 'year',
          nameProperty: 'year',
          sumProperty: 'loss',
          nameSeries: 'Year',
          header: 'Year',
          headerRecord: 'years'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barChartsStacked: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'arraySumStacked',
          propertyPrimary: 'risks',
          namePropertyPrimary: 'name',
          propertySecondary: 'assetclasses',
          namePropertySecondary: 'name',
          sumProperty: 'loss',
          nameSeries: 'Risks and asset classes',
          header: 'Risks and asset classes',
          headerRecord: 'risks'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: '800px',
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barChartsStacked2: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'arraySumStacked',
          propertyPrimary: 'risks',
          namePropertyPrimary: 'name',
          propertySecondary: 'producttypes',
          namePropertySecondary: 'name',
          sumProperty: 'loss',
          nameSeries: 'Risks and product types',
          header: 'Risks and product types',
          headerRecord: 'risks'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: '800px',
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barChartsStacked3: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'arrayStacked',
          propertyPrimary: 'risks',
          namePropertyPrimary: 'name',
          propertySecondary: 'assetclasses',
          namePropertySecondary: 'name',
          nameSeries: 'Risks and asset classes',
          header: 'Risks and asset classes',
          headerRecord: 'risks'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: '800px',
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barChartsStacked4: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'arrayStacked',
          propertyPrimary: 'risks',
          namePropertyPrimary: 'name',
          propertySecondary: 'producttypes',
          namePropertySecondary: 'name',
          nameSeries: 'Risks and product types',
          header: 'Risks and product types',
          headerRecord: 'risks'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: '800px',
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    rows: function () {
      return this.$store.state.rowsShow
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "MAR Cases Stratification"
    this.$stat.log({ page: 'cases datastrats', action: 'open cases datastrats' })
  }
}
</script>

<style>
</style>
